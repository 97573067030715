@import './colors.scss';

/* The following block can be included in a custom.scss */

/* make the customizations */
$theme-colors: (
    "primary": red,
    "secondary": grey,
    "info": tomato,
    "error": red,
    "warning": orange,
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";


.btn, button{
    border-radius: 0;
    &:focus {
        outline: none;
    }
}

.alert.alert-danger{
    background-color: rgb(184, 0, 0);
    border: none;
    color: white;

    .close{
        opacity: 0.2;
    }
}