.score-board{
    h4{
        text-align: center;
        padding: 0.5em 0;
        margin: 0;
    }

    .users-list {
        .user-score-container{
            border-bottom: solid 1px rgba(0, 0, 0, 0.3);
        }
    }
}