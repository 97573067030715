.sets-page--game {
  display: flex;
  flex-direction: row;

  background-color: #2b2a27;

  &.game-ask-deal {
    background-color: #633f09;
  }

  &.game-state--buzzed {
    background-color: #742020;
  }

  position: relative;

  .main-content {
    flex: 1;
    overflow: auto;
    display: flex;
    // padding: 2em;
    padding: 5vh 5vw;
  }

  .mobile-buttons {
    display: none;
  }

  @media (max-width: 950px) {
    flex-direction: column;

    .mobile-buttons {
      display: flex;

      .game-button-bar {
        display: flex;
        flex-direction: row-reverse;
        .btn {
          height: 100%;
          margin: 0;
          padding: 0.5em;
        }
      }
    }
  }
}
