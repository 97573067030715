@import "~bootstrap/scss/bootstrap-grid.scss";

.sets-page {
  height: 100%;
  width: 100%;
  overflow: auto;

  background-color: #2b2a27;
  color: white;

  .logo-link {
    color: white;
    text-decoration: none;
  }

  hr {
    border-color: rgba(255, 255, 255, 0.1);
    width: 90%;
    margin: 4em 0;
  }

  .main-page-content {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;

    align-items: center;

    padding: 2em 1em;

    @include media-breakpoint-up(md) {
      padding: 2em;
    }
  }
}

.sets-page-common {
  height: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 2em;

  & > * {
    flex-shrink: 0;
  }

  
  .main-page-content {
    padding: 0;
    margin: 2em;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 0.5em;
    .main-page-content{
      margin: 0.5em
    }
  }

  .sets-logo {
    font-size: 10em;
  }

  input {
    margin: 0;
  }

  .rules {
    width: 100%;
  }
}
