.sets-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0.2em;

  border-radius: 1em;
  background-color: #efefef;
  border: solid 5px white;

  box-shadow: 0px 0px 5px rgba(200, 200, 200, 0.2),
    inset 0px 0px 0px 3px darkslategrey;

  $size: 11em;
  height: $size * 1.54;
  width: $size;

  &:focus {
    outline: none;
  }

  svg {
    width: 100%;

    .stripe line {
      fill: white;
      stroke: white;
    }
    .striped {
      mask: url(#mask);
    }
  }
}

.sets-card.card-selected {
  border-color: orange;
  background-color: rgb(240, 240, 240);
}
