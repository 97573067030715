.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 2em;
    padding: 2em;

    h4 {
        margin: 1.5em 0;
    }

    a, a:hover {
        color: inherit;
        text-decoration: none;
        text-align: center;
    }

    color: rgba(250,250,250,0.7);
}