.board {
  display: grid;

  width: 100%;

  grid-template-columns: repeat(4, 1fr);
  gap: 1em;

  .sets-card {
    height: calc(min(25vh, 30vw));
    width: calc(min(25vh, 30vw) * 0.65);
  }

  @media (max-width: 950px) {
    .sets-card {
      height: Min(20vh, calc(20vw * 1.4));
      width: calc(Min(20vh, calc(20vw * 1.4)) * 0.65);
    }
    @media (max-width: 60vh) {
      grid-template-columns: repeat(3, 1fr);
      .sets-card {
        height: calc(min(16vh, 40vw));
        width: calc(min(16vh, 40vw) * 0.65);
      }
    }

    @media (max-height: 60vw) {
      grid-template-columns: repeat(6, 1fr);
      .sets-card {
        height: calc(min(25vh, 40vw));
        width: calc(min(25vh, 40vw) * 0.65);
      }
    }
  }

  &.oversized {
    grid-template-columns: repeat(5, 1fr);
    .sets-card {
      height: calc(min(26vh, 15vw));
      width: calc(min(26vh, 15vw) * 0.65);
    }
    @media (max-width: 950px) {
      .sets-card {
        height: calc(Min(25vh, calc(20vw * 1.15)));
        width: calc(Min(25vh, calc(20vw * 1.15)) * 0.65);
      }
    }

    @media (max-width: 80vh) {
      grid-template-columns: repeat(4, 1fr);
      .sets-card {
        height: calc(min(16vh, 40vw));
        width: calc(min(16vh, 40vw) * 0.65);
      }
    }

    @media (max-width: 60vh) {
      grid-template-columns: repeat(3, 1fr);
      gap: 0.5em;
      .sets-card {
        height: calc(min(14vh, 40vw));
        width: calc(min(14vh, 40vw) * 0.65);
      }
    }
  }


  align-items: center;
  justify-items: center;

  margin: auto;
  width: fit-content;
}
