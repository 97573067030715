.cards-left {
  display: flex;
  align-items: center;

  padding: 1em 0;
  display: flex;
  justify-content: center;

  .cards-left-text {
    font-size: 1.1em;
    text-transform: capitalize;
  }

  .cards-left-icon {
    $size: 1.75em;
    width: $size * 1.54;
    height: $size;

    font-size: $size / 1.5;
    background-color: white;
    border: solid 2px white;
    box-shadow: inset 0px 0px 0px 2px black, 0px 0px 3px rgba(0, 0, 0, 0.3);
    border-radius: $size / 5;
    color: black;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 0.5em;
  }
}
