@import "~bootstrap/scss/bootstrap-grid.scss";
@import "../../styles/colors.scss";

.sets-page.sets-page--home {

  .sets-logo {
    font-size: 10em;
  }

  .join-forms {

    width: 100%;

    .alert {
      border-radius: 0.5em;
      margin-bottom: 2em;
    }

    .share-room{
      margin-bottom: 2em;
    }
    .inputs {
      display: flex;
      flex-direction: column;
      width: 100%;

      border-radius: 0.5em;
      overflow: hidden;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
      background-color: $prune;

      margin-bottom: 1.5em;
    }

    input {
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: solid 3px rgba(0, 0, 0, 0.3);

      color: white;

      padding: 1em 1.5em;
      height: fit-content;

      &::placeholder {
        color: rgba(255, 255, 255, 0.4);
      }

      &:focus {
        box-shadow: none;
        border-color: orange;
      }
    }

    .btn {
      width: 15em;
      max-width: 100%;
      margin-top: 1em;
      &:focus {
        box-shadow: none;
      }

      width: 100%;
      border-radius: 0.5em;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    }

    & > * {
      width: 100%;
    }
  }

  .rules {
    background-color: $prune;
    border-radius: 0.5em;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    padding: 1.5em 2em;
    margin-bottom: 2em;
    text-align: left;
  }
}
