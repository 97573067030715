@import "~bootstrap/scss/bootstrap-grid.scss";
@import "../../styles/colors.scss";

.sets-page.sets-page--lobby {
  .main-page-content {
    & > * {
      border-radius: 0.5em;
      overflow: hidden;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    }
  }

  .lobby-text {
    box-shadow: none;
    font-family: "Squada One", cursive;
    margin-bottom: 1.5em;
    text-align: center;
    h3{
      font-size: 3em;
    }
    p {
      font-size: 1.7em;
    }
  }

  .score-board {
    h4 {
      background-color: rgba(0, 0, 0, 0.3);
    }
    width: 100%;
    background-color: $prune;
    margin: 2em 0;

    .user-score{
      display: none;
    }
  }

  .users-list {
    .user-score-container:last-child {
      border: none;
    }
  }

  .rules {
    background-color: $prune;
    padding: 1.5em 2em;
    margin-bottom: 2em;
  }
}
