.user-score-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0.5em 1em;

  &:hover{
    background-color: rgba(0, 0, 0, 0.1);
  }

  .user-image {
    height: 2em;
    width: 2em;
    border-radius: 50%;
    text-align: center;

    background-color: rgba(0,0,0,0.2);
    border: solid 2px orange;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .user-username {
    flex: 1;
    margin: 0 1em;
  }
  .user-score {
    font-size: 1.5em;
    margin: 0;
  }

  .badge {
    margin: 0 0.75em;
  }
}
