.side-bar {
  @media (max-width: 950px) {
    display: none;
    &.open {
      position: absolute;
      display: flex;
      height: calc(100% - 4em);
      width: 100%;
      top: 4em;

      .sets-logo, 
      .game-infos,
      .game-button-bar{
          display: none;
      }

    }
  }

  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;

  width: 20em;

  background-color: #581845;
  box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.2);
  color: white;

  .row-infos {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .sets-logo {
    font-size: 5em;
  }

  .sets-logo,
  .score-board h4,
  .dark-bg {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .game-infos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    & > * {
      padding: 1em 0;
      text-align: center;
      &:not(:last-child) {
        border-right: solid 1px rgba(0, 0, 0, 0.3);
      }
      background-color: rgba(0, 0, 0, 0.3);

      &:hover {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
    .time-left {
      flex: 1;
    }

    .cards-left {
      flex: 2;
    }
  }

  .game-button-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .score-board {
    flex: 1;
    width: 100%;
    overflow: auto;
  }
}
